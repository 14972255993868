@import "../../../antd.customize.less";
@import "./typography.scss";
@import "./spaces.scss";

* {
  &:hover,
  &:active,
  &:focus {
    outline: 0px !important;
  }
}

body {
  background-color: #eef0f3;
}

textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active,
.btn.focus,
.btn:focus {
  outline: 0px !important;
  -webkit-appearance: none;
  box-shadow: none !important;
}

img {
  max-width: 100%;
  height: auto;
}

.rounded {
  border-radius: 0.25rem !important;
  img {
    border-radius: 0.25rem !important;
  }
}

// antd label from required
.ant-form-item-label {
  > label {
    &.ant-form-item-required {
      &:not(.ant-form-item-required-mark-optional):before {
        display: none !important;
      }
    }
  }
}

.ant-tabs {
  &.customer-tabs {
    margin-top: 25px;
  }
}

.ant-page-header-heading-title {
  line-height: initial !important;
}

.title-filter-point {
  padding: 0 !important;
  .ant-page-header-heading-left {
    align-items: baseline !important;
    flex-direction: column;
  }
}
.ql-editor {
  min-height: 40em;
}
.ant-upload {
  &.ant-upload-select-picture-card {
    width: 100%;
    height: 200px;
  }
}

.title-heading-extra-icon {
  .ant-page-header-heading-extra {
    margin-left: auto;
    display: flex;
    button {
      display: flex;
      align-items: center;
    }
  }
}

.sortable-hoc-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: auto;
  padding: 24px 12px 64px;
  overflow-y: auto;
}

.sortable-hoc-item {
  display: flex;
  padding: 16px;
  position: relative;
  max-width: 25%;
  // flex-basis: 100%;
  border-radius: 2px;
  flex-direction: column;
}

.sortable-hoc-inner-item {
  display: flex;
  max-width: 100%;
  max-height: 100%;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.SortableList {
  position: relative;
  z-index: 0;
  background-color: #f3f3f3;
  border: 1px solid #efefef;
  border-radius: 3px;
  outline: none;

  width: 400px;
  height: auto;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border: 1px solid #e0e0e0;
  list-style: none;
  padding: 0;
}
.SortableItem {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 0 20px;
  background-color: #fff;
  border-bottom: 1px solid #efefef;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #333;
  font-weight: 500;
}
.SortableHelper {
  box-shadow: rgba(0, 0, 0, 0.075) 0px 1px 6px, rgba(0, 0, 0, 0.075) 0px 1px 4px;
  background-color: rgba(255, 255, 255, 0.8);
  cursor: row-resize;
}

.sortable-close {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border: 0 !important;
}

.sketch-picker{
  width: 97% !important;
  box-shadow: "rgb(0 0 0 / 15%) 0px 0px 0px 1px, rgb(0 0 0 / 15%) 0px 0px 19px";
}