@font-family: Arial, Helvetica, sans-serif;
@primary-color: #009c93;
@link-color: #009c93; // link color
@success-color: #52c41a; // success state color
@warning-color: #faad14; // warning state color
@error-color: #e74c3c; // error state color
@font-size-base: 14px; // major text font size
@heading-color: rgba(0, 0, 0, 0.85); // heading text color
@text-color: #2c3e50; // major text color
@text-color-secondary: #7f8c8d; // secondary text color
@disabled-color: #7f8c8d; // disable state color
@border-radius-base: 2px; // major border radius
@border-color-base: #d9d9d9; // major border color
@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
  0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05); // major shadow for layers
